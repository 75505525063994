import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import { protectedRoutes } from '../../routes/protected';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { AccountBalanceWallet, Logout } from '@mui/icons-material';
import { removeLocalStorageItem } from '../../utils/local-storage';
import { useCallback } from 'react';
import { thousandSeparator } from '../../utils/price';
import { useAppSelector } from '../../redux';

const Profile = () => {
  const navigate = useNavigate();
  const purchaseBalance = useAppSelector((state) => state.purchaseBalance.data);
  const handleLogout = useCallback(() => {
    removeLocalStorageItem(`${window.location.host}-token`);
    navigate('/login');
  }, [navigate]);
  return (
    <Paper>
      <List component="nav">
        <List component="div" disablePadding>
          {purchaseBalance?.has_purchase_limit && (
            <ListItem>
              <ListItemIcon>
                <AccountBalanceWallet />
              </ListItemIcon>
              <ListItemText primary={'سقف خرید'} />
              <ListItemText
                dir={'ltr'}
                sx={{ display: 'flex', justifyContent: 'flex-start', mr: 2 }}
                primary={thousandSeparator(purchaseBalance?.purchase_limit ?? 0) + ' ریال '}
              />
            </ListItem>
          )}

          {protectedRoutes?.map((route) => {
            if (route.parent === 'category' || !route.parent) return null;
            return (
              <ListItemButton sx={{ pl: 4 }} onClick={() => navigate(route.path)}>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.name} />
                <IconButton>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </ListItemButton>
            );
          })}
          <ListItemButton sx={{ pl: 4 }} onClick={() => handleLogout()}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={'خروج'} />
          </ListItemButton>
        </List>
      </List>
    </Paper>
  );
};
export default Profile;
