import { Box, Typography } from '@mui/material';
import { StyledRoundedImg } from '../Products/components/RoundedImg.styles';
import LazyImg from '../../components/loading/LazyImg';
import aboutUsBanner from '../../assets/img/aboutUseBanner.jpg';
import PlaceholderImg from '../../assets/img/lazyBanners.png';
import { useAppSelector } from '../../redux';

const AboutUs = () => {
  const company = useAppSelector((state) => state.companyInfo.data);
  return (
    <>
      <Box p={2}>
        <StyledRoundedImg>
          <LazyImg src={aboutUsBanner} alt={'about-us'} PlaceholderImg={PlaceholderImg} />
        </StyledRoundedImg>
        <Typography my={5} lineHeight={'2.1rem'} textAlign={'justify'}>
          این فروشگاه بعنوان یکی از مزایای حمایتی گروه بوتان برای پشتیبانی از همکاران و تسهیل خرید
          برخی محصولات ایجاد گردیده و به هیچ وجه ماهیت سودآور و انتفاعی ندارد بلکه صرفا تلاش می کند
          نیاز همکاران برای تامین برخی کالاهای پر مصرف را با حداقل قیمت ممکن برآورده سازد. بنابراین
          همچون سایر فروشگاههای اینترنتی، تنوع در اقلام و برندها و گسترش سبد کالاها، جزو سیاستهای آن
          نمی باشد
        </Typography>
      </Box>
    </>
  );
};
export default AboutUs;
