import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, faIR } from '@mui/x-data-grid';
import { Box, Paper, Typography, TableBody, TableRow, Grid } from '@mui/material';
import {
  IPaymentRequest,
  useAppSelector,
  useGetOrdersQuery,
  useUploadReceiptFileMutation,
} from '../../redux';
import { useLoading } from '../../components/loading';
import { thousandSeparator } from '../../utils/price';
import { OrderState, toFarsiNumber } from '../../utils/helper';
import { useNavigate, useParams } from 'react-router-dom';
import CustomNoRowsOverlay from './components/NoRows';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { StyledBarcode, StyledStateTitle } from './OrderDetailsStyles';
import {
  ResponsiveTable,
  StyledLabel,
  StyledPriceRow,
} from '../ShopCart/components/CartTable.style';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import Barcode from 'react-barcode';
import { CButton, CFileInput } from '../../components/ui-components';
import { CForm } from '../../components/ui-components/CForm/CForm';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

interface IOrderRows {
  id: number;
  product_code: string;
  product_name: string;
  product_images: string;
  quantity: number;
  price: string;
}

function createOrderTableData(
  id: number,
  product_code: string,
  product_name: string,
  product_images: string,
  quantity: number,
  price: string
): IOrderRows {
  return {
    id,
    product_code,
    product_name,
    product_images,
    quantity,
    price,
  };
}
const OrdersDetails = () => {
  const PER_PAGE = 100;
  const params = useParams<string>();
  const [page, setPage] = useState<number>(0);
  const [pendingState, setPendingState] = useState<boolean>(true);
  const [rows, setRows] = useState<IOrderRows[]>([]);
  const { setIsLoading } = useLoading();
  const profile = useAppSelector((state) => state.profile.data);
  const existingTheme = useTheme();
  const theme = useMemo(
    () =>
      createTheme({}, faIR, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme]
  );
  const navigate = useNavigate();
  const {
    data: order,
    isLoading,
    refetch,
  } = useGetOrdersQuery(
    {
      pagination: {
        page: page,
      },
      search: {
        searchFields: 'id',
        searchValue: params?.id,
      },
      per_page: PER_PAGE,
    },
    { skip: !params?.id }
  );
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ردیف',
        filterable: false,
        width: 80,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box px={3}>
            {toFarsiNumber(String(params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1))}
          </Box>
        ),
      },
      {
        field: 'product_images',
        headerName: 'عکس محصول',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box py={4}>
            <img src={params.value} height={60} />
          </Box>
        ),
      },
      {
        field: 'product_code',
        headerName: 'کد محصول',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams<any>) => <span dir="ltr">{params.value}</span>,
      },
      {
        field: 'product_name',
        headerName: 'نام محصول',
        headerAlign: 'center',
        align: 'center',
        width: 250,
      },
      {
        field: 'quantity',
        headerName: 'تعداد',
        headerAlign: 'center',
        align: 'center',
        width: 110,
        type: 'number',
        renderCell: (params: GridRenderCellParams<any>) => toFarsiNumber(String(params.value)),
      },

      {
        field: 'price',
        headerName: 'جمع قیمت (ریال)',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<any>) => {
          if (params.value == null) {
            return '';
          }
          return `${thousandSeparator(Number(params.value) * Number(params.row.quantity))} ریال`;
        },
        type: 'number',
      },
    ];
  }, [rows, navigate]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (typeof order !== 'undefined') {
        let transformRows: IOrderRows[] = [];
        order?.data?.length > 0 &&
          order?.data[0]?.subOrder?.length > 0 &&
          order?.data[0]?.subOrder?.map((item) => {
            transformRows.push(
              //create newData for table
              createOrderTableData(
                item?.id ?? 0,
                item?.product_detail?.product_code ?? '-',
                item?.product_detail?.name ?? '-',
                item?.product_detail?.images,
                item?.quantity ?? 0,
                item?.price ?? 0
              )
            );
          });
        setRows(transformRows);
        if (order?.data[0]?.state === 'pending') {
          setPendingState(false);
        }
      }
    }
  }, [isLoading]);

  const [uploadReceipt] = useUploadReceiptFileMutation();
  const formProvider = useForm<IPaymentRequest>({
    mode: 'onChange',
  });
  const uploadFile = (data: IPaymentRequest) => {
    uploadReceipt({ ...data, order_id: String(params.id) })
      .unwrap()
      .then((res) => {
        //TODO:after fix api response remove[]
        enqueueSnackbar('رسید پرداختی شما با موفقیت ارسال شد.', {
          variant: 'success',
        });
        refetch();
        setPendingState(true);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box px={2}>
      <Typography my={5} variant="h6">
        تاریخچه سفارش {order?.data[0]?.id}
        &nbsp;
        {order?.data[0]?.state ? (
          <StyledStateTitle>({OrderState(order?.data[0]?.state)})</StyledStateTitle>
        ) : null}
      </Typography>
      {profile?.company?.payment_type === 'manual' && !pendingState ? (
        <Grid container>
          <Typography fontWeight={'bold'} fontSize={'1rem'} mb={3}>
            فیش واریزی را آپلود کنید و
            <Box component="span" sx={{ color: theme.palette.primary.main, mx: 2 }}>
              برای ثبت نهایی آن دکمه ارسال را کلیک کنید.
            </Box>
          </Typography>
          <CForm<IPaymentRequest>
            id="uploadRecieption"
            formProviderProps={formProvider}
            onSubmit={uploadFile}
          >
            <CFileInput
              name="image"
              control={formProvider.control}
              defaultValue=""
              formProvider={formProvider}
              inputProps={{
                fullWidth: true,
              }}
              rules={{
                required: true,
              }}
              gridProps={{
                xs: 12,
                md: 6,
              }}
            />
            <Grid item xs={12} md={6} container justifyContent="start">
              <CButton
                color="warning"
                variant="contained"
                type="submit"
                loading={false}
                disabled={false}
                sx={{ width: '100%' }}
              >
                ارسال
              </CButton>
            </Grid>
          </CForm>
        </Grid>
      ) : null}

      <StyledBarcode>
        <Barcode
          value={order?.data[0]?.barcode ?? ''}
          displayValue={false}
          background={'transparent'}
        />
      </StyledBarcode>
      <Paper>
        <ThemeProvider theme={theme}>
          <DataGrid
            rowHeight={90}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: PER_PAGE },
              },
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoading}
            pageSizeOptions={[PER_PAGE / 2, PER_PAGE]}
            onPaginationModelChange={(e) => setPage(e.page + 1)}
          />
        </ThemeProvider>
      </Paper>
      <Box mt={5} pt={3}>
        <Paper>
          <ResponsiveTable>
            <Table>
              <TableBody>
                <StyledPriceRow>
                  {order?.data[0]?.profit ? (
                    <TableCell align={'center'}>
                      سود شما از کل خرید
                      <StyledLabel>
                        <Typography fontWeight={'bold'}>
                          <span dir={'ltr'} style={{ marginLeft: 3 }}>
                            {order?.data[0]?.profit && thousandSeparator(order?.data[0]?.profit)}
                          </span>
                          ریال
                        </Typography>
                      </StyledLabel>
                    </TableCell>
                  ) : null}

                  <TableCell align={'center'}>
                    جمع کل خرید
                    <StyledLabel>
                      <Typography fontWeight={'bold'}>
                        <span dir={'ltr'} style={{ marginLeft: 3 }}>
                          {order?.data[0]?.total_price &&
                            thousandSeparator(Number(order?.data[0]?.total_price))}
                        </span>
                        ریال
                      </Typography>
                    </StyledLabel>
                  </TableCell>
                </StyledPriceRow>
                <TableRow sx={{ display: { xs: 'none', sm: 'table-row' } }}>
                  {order?.data[0]?.profit ? (
                    <TableCell align={'center'}>
                      <Typography fontWeight={'bold'}>
                        <span dir={'ltr'} style={{ marginLeft: 3 }}>
                          {order?.data[0]?.profit && thousandSeparator(order?.data[0]?.profit)}
                        </span>
                        ریال
                      </Typography>
                    </TableCell>
                  ) : null}

                  <TableCell align={'center'}>
                    <Typography fontWeight={'bold'}>
                      <span dir={'ltr'} style={{ marginLeft: 3 }}>
                        {order?.data[0]?.total_price &&
                          thousandSeparator(Number(order?.data[0]?.total_price))}
                      </span>
                      ریال
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ResponsiveTable>
        </Paper>
      </Box>
    </Box>
  );
};
export default OrdersDetails;
