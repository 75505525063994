import React, { useEffect } from 'react';
import {
  Box,
  ListItem,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemText,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLoading } from '../../components/loading';
import { IFaq, useAppSelector, useGetFaqQuery } from '../../redux';
import { toFarsiNumber } from '../../utils/helper';
import { useLocation } from 'react-router-dom';

const Faq = () => {
  const company = useAppSelector((state) => state.companyInfo.data);
  const { setIsLoading } = useLoading();
  const { data, isLoading } = useGetFaqQuery({});
  const location = useLocation();
  useEffect(() => {
    if (isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);
  useEffect(() => {
    if (location.pathname === '/faq#help')
      // 👇️ scroll to top on page load
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);
  return (
    <Grid p={2}>
      <Box display={'flex'} justifyContent={'center'} my={3}>
        <HelpOutlineIcon color="info" sx={{ padding: '0 5px' }} fontSize="large" />
        <Typography fontWeight={'bold'} variant="h5">
          راهنما
        </Typography>
      </Box>
      <Box>
        <Typography align="center" fontWeight={'bold'} color={'primary'}>
          خرید از این فروشگاه، فقط برای همکاران گروه بوتان که دارای کد پرسنلی فعال باشند (سایتهای
          مشمول طرح)، مجاز و میسر می باشد.
        </Typography>
      </Box>
      <Box my={5} pt={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight={'bold'} mx={2}>
              نحوه درخواست و تحویل سفارشات
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box px={2}>
              <Typography>
                خرید از فروشگاه، شامل مراحل زیر می‌باشد و در هر مرحله پیامک مخصوصی برای اطلاع رسانی
                به متقاضی ارسال می گردد:
              </Typography>
              <Box mx={2}>
                <List sx={{ listStyle: 'decimal', pl: 4 }}>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="وضعیت در انتظار پرداخت : ثبت درخواست و تایید جمع کل فاکتور توسط متقاضی" />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="وضعیت در انتظار تایید پرداخت : واریز جمع مبلغ فاکتور توسط متقاضی" />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="وضعیت در انتظار بسته بندی : انجام کنترل های لازم و تایید سفارش توسط ادمین" />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="وضعیت آماده تحویل : بسته بندی کالاها بهمراه فاکتور فروش، توسط مسئول فروشگاه" />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="وضعیت تحویل داده شد : دریافت حضوری سفارشات در بسته های پلمب شده" />
                  </ListItem>
                </List>
              </Box>
              <Box mx={5}>
                <List sx={{ listStyle: 'disc', pl: 5 }}>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="تحویل سفارشات فقط در پایان شیفت های کاری و در هنگام خروج همکاران میسر خواهد بود." />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText
                      primary={`برای دریافت سفارشات، اعلام "شماره سفارش" الزامی است.`}
                    />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="مسئولیت کنترل بسته ها و کالاها در هنگام تحویل، بر عهده متقاضی می‌باشد ." />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="دریافت بسته ها و خروج از فروشگاه به منزله تحویل کامل و سالم سفارش تلقی می گردد." />
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <ListItemText primary="کالاهای خریداری شده از فروشگاه، فقط درصورتیکه پلمب بسته ها باز نشده باشند، امکان خروچ از شرکت را خواهند داشت." />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography mx={2} fontWeight={'bold'}>
              ضوابط لغو سفارش، مرجوعی و تعویض کالا
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box mx={4}>
              <List sx={{ listStyle: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText
                    primary={`تا زمانیکه پرداخت وجه سفارش انجام نشده باشد، سفارش "نهایی" تلقی نخواهد شد و امکان لغو آن توسط متقاضی وجود دارد.`}
                  />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText
                    primary={`عد از پرداخت وجه (نهایی شدن سفارش)، امکان ابطال درخواست، تغییر کالاهای انتخابی و یا مرجوع شدن وجه پرداختی توسط متقاضی وجود نخواهد داشت.`}
                  />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText
                    primary={`در هنگام تحویل سفارش، در صورت وجود هر گونه اشکال در اجناس و یا اشتباه در اقلام بسته بندی شده، امکان تعویض وجود خواهد داشت و نسبت به پلمب مجدد بسته دارای اشکال، اقدام می گردد.`}
                  />
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <ListItemText
                    primary={`با توجه به حساسیت اقلام فروشگاه و رعایت اصول بهداشتی مواد خوراکی، بعد از تحویل بسته های سفارش و خروج متقاضی از فروشگاه، در صورتیکه پلمب بسته ها باز نشده باشد، تا 24 ساعت امکان تعویض کالاهایی که احتمالا دچار آسیب یا اشکال باشند، وجود خواهد داشت.`}
                  />
                </ListItem>
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box my={5} pt={2}>
        <Box display={'flex'} justifyContent={'center'} my={3} pb={2}>
          <HelpOutlineIcon color="info" sx={{ padding: '0 5px' }} fontSize="large" />
          <Typography fontWeight={'bold'} variant="h5">
            پرسش های متداول
          </Typography>
        </Box>
        {data?.data?.map((faq: IFaq, index) => {
          return (
            <Accordion key={faq.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={'bold'} mx={2}>
                  {toFarsiNumber(String(index + 1))}. {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box px={2}>
                  <Typography textAlign={'justify'} lineHeight={'2rem'}>
                    {faq.description}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Grid>
  );
};
export default Faq;
