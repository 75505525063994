import { Box, Divider, Typography } from '@mui/material';
import { IProduct } from '../../../redux';

interface IResultSearchBox {
  item: IProduct;
  onclick: () => void;
}

export const ResultSearchItem = ({ item, onclick }: IResultSearchBox) => {
  return (
    <>
      <Box p={1} key={item.id} display={'flex'} alignItems={'center'} onClick={onclick}>
        <Box component={'img'} width={60} height={60} src={item.images?.[0]?.url} />
        <Box px={2}>
          <Typography fontWeight={'bold'} variant="body2" mb={1}>
            {item.name}
          </Typography>
          <Typography className="description" variant="body2">
            {item.description}
          </Typography>
        </Box>
      </Box>
      <Box px={3} my={1}>
        <Divider />
      </Box>
    </>
  );
};
