import { useParams } from 'react-router-dom';
import {
  IAddRemoveShopCartRequest,
  IProduct,
  addShop,
  deleteShop,
  selectTotalQuantityShopCart,
  useAddShopCartMutation,
  useAppDispatch,
  useAppSelector,
  useGetProductsQuery,
  useRemoveShopCartMutation,
} from '../../redux';
import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  StyledIconButton,
  StyledIconButtonsBox,
  StyledImgProductDetails,
  StyledImgSliderProductDetails,
  StyledProImgGrid,
  StyledProductDetails,
} from './ProductDetails.styles';
import { CButton } from '../../components/ui-components';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { thousandSeparator } from '../../utils/price';
import { useCallback, useEffect, useState } from 'react';
import ProductDetailsSkeleton from './components/ProductDetailsSkeleton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import { Breadcrumb } from '../../components/breadcrumbs/Breadcrumb';
import { useSelector } from 'react-redux';
import { toFarsiNumber } from '../../utils/helper';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

const ProductDetails = () => {
  const params = useParams<string>();
  const [count, setCount] = useState<number>(0);
  const [shopId, setShopId] = useState<number>(0);
  const [productImages, setProductImages] = useState<{ original: string; thumbnail: string }[]>([]);
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetProductsQuery(
    {
      search: {
        searchValue: params?.id,
        searchFields: 'id',
      },
      pagination: {
        page: 1,
      },
      per_page: 1,
    },
    { skip: !params?.id }
  );
  const [addShopCart] = useAddShopCartMutation({});
  const [removeShopCart] = useRemoveShopCartMutation({});
  const onAddCart = (data: IAddRemoveShopCartRequest, item?: IProduct) => {
    addShopCart({
      ...data,
    })
      .unwrap()
      .then((res) => {
        setCount(data.quantity);
        dispatch(addShop({ ...data, item, cart_id: res?.data?.id })); //Todo: product_id replaced by res from api
      })
      .catch((err) => {});
    //Todo:show message when necessary
  };
  const onDeleteCart = (data: IAddRemoveShopCartRequest) => {
    removeShopCart({
      ...data,
    })
      .unwrap()
      .then((res) => {
        dispatch(deleteShop(data));
      })
      .catch((err) => {});
    //Todo:show message when necessary
  };
  const shopCart = useAppSelector((state) => state.shopCart.data);
  const shopCount = useSelector(selectTotalQuantityShopCart);
  useEffect(() => {
    let countOfProduct;
    let ShopCartId;
    if (typeof shopCart !== 'undefined' && shopCart?.length > 0) {
      let findItem = shopCart.find((x) => Number(x.product_id) === Number(params?.id));
      countOfProduct = findItem?.quantity;
      ShopCartId = findItem?.id;
    }
    if (typeof countOfProduct !== 'undefined') {
      setCount(countOfProduct);
    }
    if (typeof ShopCartId !== 'undefined') {
      setShopId(ShopCartId);
    }
  }, [shopCount, params.id]);

  const onRemoveShopCart = () => {
    if (count > 0) {
      typeof data?.data[0] !== 'undefined'
        ? onAddCart(
            {
              product_id: Number(params?.id),
              quantity: count - 1,
            },
            data?.data[0]
          )
        : onAddCart({
            product_id: Number(params?.id),
            quantity: count - 1,
          });
    }
    if (count === 0) {
      onDeleteCart({
        cart_id: Number(shopId),
        product_id: Number(params?.id),
        quantity: 0,
      });
    }
  };
  const onAddShopCart = (amount: number) => {
    if (Number(data?.data[0]?.count_buy_on_card) > count) {
      onAddCart(
        {
          product_id: Number(params?.id),
          quantity: amount,
        },
        data?.data[0]
      );
    }
  };
  useEffect(() => {
    let sliderImg: { original: string; thumbnail: string }[] = [];
    data?.data?.[0]?.images?.map((img) => {
      sliderImg.push({ original: img.url, thumbnail: img.url });
    });
    setProductImages(sliderImg);
  }, [data]);
  const renderItem = useCallback(
    (item: ReactImageGalleryItem) => {
      return (
        <StyledImgProductDetails mb={{ sm: 5 }}>
          <Box component="img" src={item.original} alt={item.original} />
        </StyledImgProductDetails>
      );
    },
    [productImages]
  );
  return (
    <StyledProductDetails container spacing={4} p={4}>
      {isLoading ? (
        <ProductDetailsSkeleton />
      ) : (
        <>
          <Grid item xs={12} mb={4}>
            <Breadcrumb pageName={data?.data[0]?.name} />
          </Grid>
          <StyledProImgGrid item xs={12} sm={5} md={4} lg={3} xl={3} p={0}>
            <StyledImgSliderProductDetails>
              {productImages?.length == 1 ? (
                <StyledImgProductDetails mb={{ sm: 5 }}>
                  <Box
                    component="img"
                    src={productImages?.[0]?.original}
                    alt={productImages?.[0]?.original}
                  />
                </StyledImgProductDetails>
              ) : (
                <ReactImageGallery
                  items={productImages}
                  showThumbnails={true}
                  showFullscreenButton={false}
                  isRTL={true}
                  showPlayButton={false}
                  showNav={false}
                  renderItem={renderItem}
                />
              )}
            </StyledImgSliderProductDetails>

            {count === Number(data?.data[0]?.count_buy_on_card) ? (
              <Typography
                color={'primary'}
                fontSize={'small'}
                align="center"
                my={2}
                fontWeight={'bold'}
              >
                حداکثر تعداد مجاز محصول انتخابی{' '}
                {toFarsiNumber(String(data?.data[0]?.count_buy_on_card))} می‌باشد .
              </Typography>
            ) : null}
            {count > 0 ? (
              <StyledIconButtonsBox mx={1} display={'flex'} justifyContent={'space-between'}>
                <StyledIconButton
                  size="small"
                  aria-label="add"
                  color="primary"
                  onClick={() => onAddShopCart(count + 1)}
                >
                  {Number(data?.data[0]?.count_buy_on_card) === count ? (
                    <DoNotDisturbAltOutlinedIcon color="primary" />
                  ) : (
                    <AddOutlinedIcon color="primary" />
                  )}
                </StyledIconButton>

                <Typography fontWeight={'bold'} variant="h6">
                  {toFarsiNumber(String(count))}
                  &nbsp; عدد
                </Typography>
                <StyledIconButton
                  size="small"
                  aria-label="remove"
                  color="primary"
                  onClick={() => onRemoveShopCart()}
                >
                  {count === 1 ? (
                    <DeleteOutlinedIcon color="primary" />
                  ) : (
                    <RemoveOutlinedIcon color="primary" />
                  )}
                </StyledIconButton>
              </StyledIconButtonsBox>
            ) : (
              <Box mt={5}>
                <CButton
                  fullWidth
                  variant="contained"
                  rightIcon={<AddShoppingCartRoundedIcon color="inherit" />}
                  onClick={() => {
                    onAddCart(
                      {
                        product_id: Number(params?.id),
                        quantity: count + 1,
                      },
                      data?.data[0]
                    );
                  }}
                >
                  &nbsp; افزودن به سبد خرید
                </CButton>
              </Box>
            )}
          </StyledProImgGrid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9} display={'flex'}>
            <Box pl={{ xl: '2.2rem', lg: '2.2rem', xs: '.4rem' }}>
              <Typography fontWeight={'bold'} fontSize={'medium'}>
                {data?.data[0]?.name}
              </Typography>
              {data?.data[0]?.product_code ? (
                <Box display={'flex'} sx={{ width: '100%' }} mt={3}>
                  <Typography fontWeight={'bold'} mt={3} fontSize="small">
                    کدمحصول:{' '}
                  </Typography>
                  <Typography fontSize="small" mx={2} lineHeight={'2rem'} my={1} dir={'ltr'}>
                    {data?.data[0]?.product_code}
                  </Typography>
                  <Typography fontWeight={'bold'} ml={4} mt={3} fontSize="small">
                    برند:{' '}
                  </Typography>
                  <Typography fontSize="small" mx={2} lineHeight={'2rem'} my={1}>
                    {data?.data[0]?.brand}
                  </Typography>
                </Box>
              ) : null}

              {data?.data[0]?.product_price || data?.data[0]?.sale_price ? (
                <Box sx={{ width: '100%' }} display={'flex'}>
                  <Typography fontWeight={'bold'} mt={2} fontSize="small" lineHeight={'2rem'}>
                    قیمت محصول:{' '}
                  </Typography>
                  {Number(data?.data[0]?.product_price) ===
                  Number(data?.data[0]?.sale_price) ? null : (
                    <Typography
                      fontSize="medium"
                      fontWeight={'bold'}
                      mt={2}
                      lineHeight={'2rem'}
                      mx={2}
                      sx={{ textDecoration: 'line-through' }}
                      color={'secondary'}
                    >
                      {thousandSeparator(Number(data?.data[0]?.product_price))}
                      &nbsp; ریال
                    </Typography>
                  )}
                  <Typography
                    fontSize="medium"
                    mt={2}
                    fontWeight={'bold'}
                    lineHeight={'2rem'}
                    mx={2}
                    color={'primary'}
                  >
                    {thousandSeparator(Number(data?.data[0]?.sale_price))}
                    &nbsp; ریال
                  </Typography>
                </Box>
              ) : null}
              {data?.data[0]?.description ? (
                <Box sx={{ width: '100%' }}>
                  <Typography fontWeight={'bold'} mt={4} fontSize="small">
                    توضیحات:{' '}
                  </Typography>
                  <Typography fontSize="small" lineHeight={'2rem'} my={1}>
                    {data?.data[0]?.description}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </>
      )}
    </StyledProductDetails>
  );
};
export default ProductDetails;
