//todo:after completed api we should rmv this file

import { Description } from '@mui/icons-material';

type IRuleList = {
  style: 'disc' | 'decimal' | null;
  items: IRulesData[];
};
type IRulesData = {
  id: number;
  title?: string;
  description?: string | null;
  list?: IRuleList;
};
export const RULESDATA: IRulesData[] = [
  {
    id: 1,
    title: 'معرفی',
    description: `این فروشگاه بعنوان یکی از مزایای حمایتی گروه بوتان برای پشتیبانی از همکاران و تسهیل خرید برخی محصولات ایجاد گردیده و به هیچ وجه ماهیت سودآور و انتفاعی ندارد بلکه صرفا تلاش می کند نیاز همکاران برای تامین برخی کالاهای پر مصرف را با حداقل قیمت ممکن برآورده سازد. بنابراین همچون سایر فروشگاههای اینترنتی، تنوع در اقلام و برندها و گسترش سبد کالاها، جزو سیاستهای آن نمی باشد.`,
  },
  {
    id: 2,
    title: 'دامنه استفاده',
    description: '',
    list: {
      style: 'disc',
      items: [
        {
          id: 1,
          description: `استفاده از این فروشگاه، فقط برای همکاران گروه بوتان که دارای کد پرسنلی فعال باشند (سایتهای مشمول طرح)، مجاز و میسر می باشد.`,
        },
        {
          id: 2,
          description:
            'در صورت خاتمه همکاری (به هر علت) و غیر فعال شدن کد پرسنلی، کد کاربری فروشگاه نیز غیر فعال شده و امکان خرید از فروشگاه وجود نخواهد داشت.',
        },
        {
          id: 3,
          description: `همکاران جدیدالاستخدام در سایتهای مشمول، از اولین روز کاری، امکان استفاده از این مزیت را دارا می باشند.`,
        },
      ],
    },
  },
  {
    id: 3,
    title: 'چگونگی استفاده',
    description: null,
    list: {
      style: 'disc',
      items: [
        {
          id: 1,
          description: `خرید از این فروشگاه، کاملا اختیاری بوده و هیچگونه الزامی در این خصوص برای همکاران وجود ندارد.`,
        },
        {
          id: 2,
          description: `خرید از فروشگاه، صرفا بصورت الکترونیک و با استفاده از نرم افزاری که در اختیار مشمولان قرار گرفته، بدون محدودیت زمانی صورت می گیرد.`,
        },
        {
          id: 3,
          description: `همانند سایر خریدهای اینترنتی، برای خرید از این فروشگاه نیز استفاده از گوشی هوشمند و شماره موبایل مشخصی که بنام همکار در سیستم پرسنلی ثبت شده است، ضروری می باشد.`,
        },
      ],
    },
  },
  {
    id: 4,
    title: 'نحوه درخواست و تحویل سفارشات',
    description:
      'خرید از فروشگاه شامل مراحل زیر می باشد و در هر مرحله پیامک مخصوصی برای اطلاع رسانی به متقاضی ارسال می گردد:',
    list: {
      style: 'disc',
      items: [
        {
          id: 1,
          description: 'وضعیت "در انتظار پرداخت": ثبت درخواست و تایید جمع کل فاکتور توسط متقاضی',
        },
        {
          id: 2,
          description: 'وضعیت "در انتظار تایید پرداخت": واریز جمع مبلغ فاکتور توسط متقاضی',
        },
        {
          id: 3,
          description: 'وضعیت "در انتظار بسته بندی": انجام کنترل های لازم و تایید سیستمی سفارش',
        },
        {
          id: 4,
          description:
            'وضعیت "آماده تحویل": بسته بندی کالاها به همراه فاکتور فروش توسط مسئول فروشگاه',
        },
        {
          id: 5,
          description: 'وضعیت "تحویل شد": دریافت حضوری سفارش در بسته های پلمب شده توسط متقاضی',
          list: {
            style: 'decimal',
            items: [
              {
                id: 1,
                description: 'برای دریافت سفارشات، اعلام "شماره سفارش" الزامی است.',
              },
              {
                id: 2,
                description:
                  'مسئولیت کنترل بسته ها و کالاها در هنگام تحویل، بر عهده متقاضی می باشد.',
              },
              {
                id: 3,
                description:
                  'دریافت بسته ها و خروج از فروشگاه به منزله تحویل کامل و سالم کلیه اقلام سفارش تلقی می گردد.',
              },
            ],
          },
        },
      ],
    },
  },
];
