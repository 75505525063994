import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { setLocalStorageItem } from '../../utils/local-storage';
import { ILoginRequest, IOTPRequest } from '../../redux/rtkQuery/api-types';
import { CTextField, CButton } from '../../components/ui-components';
import { useState } from 'react';
import {
  useGetCompaniesInfoQuery,
  useLazyGetOTPQuery,
  useLoginMutation,
} from '../../redux/rtkQuery/api-services';
import { EditOutlined } from '@mui/icons-material';
import { useFormErrors } from '../../utils/useFormErrors';
import { useLoading } from '../../components/loading';
import { StyledLogin } from './Login.styles';
import { toEnglishNumber } from '../../utils/helper';

import { setCompanyInfo, useAppDispatch, useAppSelector } from '../../redux';
import CModal from '../../components/modals/CModal';
import Footer from '../../components/footers/Footer';

type TModalItem = {
  url: string;
};
export type TModal = {
  show: boolean;
  title?: string;
  text?: string;
  item?: TModalItem;
};

const Login = () => {
  const { handleSubmit: handleLoginSubmit, control: loginControl } = useForm<ILoginRequest>();
  const { handleSubmit: handleOTPSubmit, control: otpControl } = useForm<IOTPRequest>();
  const { setIsLoading } = useLoading();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [phone, setPhone] = useState<string>('');
  const [getOTP, { isLoading: OTPLoading }] = useLazyGetOTPQuery({});
  const [login, { isLoading: loginLoading }] = useLoginMutation({});
  const [phoneErr, setPhoneErr] = useState<boolean>(false);
  const [codeErr, setCodeErr] = useState<boolean>(false);
  const INITIALCOUNT: number = 180;
  const [counter, setCounter] = useState<number>(INITIALCOUNT);
  const { setFormErrors: setOTPFormErr } = useFormErrors();
  const { setFormErrors: setLoginFormErr } = useFormErrors();
  const { data: companiesInfo } = useGetCompaniesInfoQuery({});
  const company = useAppSelector((state) => state.companyInfo.data);
  const [modal, setModal] = useState<TModal>({
    show: false,
    title: 'توجه',
    text: '',
    item: { url: '' },
  });
  const handleModal = useCallback(
    (show: boolean) => {
      setModal({ ...modal, show });
    },
    [modal]
  );

  const checkValidation = (val: string) => {
    let enNumber = String(toEnglishNumber(val));
    if ((enNumber.match(/(\+?0)?(9\d{9})/) && enNumber.length === 11) || enNumber.length === 0) {
      setPhoneErr(false);
    } else {
      setPhoneErr(true);
    }
  };
  const checkOTP = (val: string) => {
    let enNumber = String(toEnglishNumber(val));
    if ((enNumber.match(/^[0-9\b]+$/) && enNumber.length === 6) || enNumber.length === 0) {
      setCodeErr(false);
    } else {
      setCodeErr(true);
    }
  };

  // useEffect(() => {
  if (showOTPBox) {
    const interval = setTimeout(() => {
      if (counter === 0) {
        return () => clearTimeout(interval);
      }
      let val = counter - 1;
      setCounter(val);
    }, 1000);
  }
  // });

  const onOTPSubmit = (data: IOTPRequest) => {
    getOTP({
      ...data,
      mobile: toEnglishNumber(data.mobile),
    })
      .unwrap()
      .then((res) => {
        setPhone(data.mobile);
        setShowOTPBox(true);
        setCounter(INITIALCOUNT);
      })
      .catch((err) => setOTPFormErr(err));
  };

  const onLoginSubmit = (data: ILoginRequest) => {
    login({
      ...data,
      mobile: toEnglishNumber(phone),
      verification_code: toEnglishNumber(data.verification_code),
    })
      .unwrap()
      .then((res) => {
        if (res?.access_token) {
          setLocalStorageItem(`${window.location.host}-token`, res?.access_token);
          res?.expires_at && setLocalStorageItem('expires_at', res?.expires_at);
          navigate('/');
        } else {
          if (res?.url && res?.message) {
            setModal({ ...modal, show: true, text: res.message, item: { url: res?.url } });
          }
        }

        setShowOTPBox(false);
      })
      .catch((err) => setLoginFormErr(err));
  };
  useEffect(() => {
    if (location.pathname === '/login') {
      setIsLoading(false);
    }
  }, [location]);
  useEffect(() => {
    if (location.pathname === '/login') {
      setIsLoading(false);
    }
  }, []);
  const BASEURL = window.location.protocol + '//' + window.location.host;
  useEffect(() => {
    companiesInfo?.data?.map((companyInfo) => {
      if (typeof companyInfo !== 'undefined') {
        if (
          companyInfo?.base_url === BASEURL ||
          (process.env.NODE_ENV === 'development' && 'http://172.20.10.2:3000' === BASEURL)
        ) {
          dispatch(setCompanyInfo(companyInfo));
        }
      }
    });
  }, [companiesInfo]);

  useEffect(() => {
    if (typeof company?.favicon !== 'undefined' && company?.favicon !== null) {
      document.querySelector('#shop-icon')?.setAttribute('href', company?.favicon);
    }
    if (typeof company?.app_icon !== 'undefined' && company?.app_icon !== null) {
      let url = BASEURL + '/manifest.json';
      if (company?.app_icon !== 'butane.png') {
        url = BASEURL + '/' + company?.app_icon.split('.')[0] + 'manifest.json';
      }
      document.querySelector('#manifest-placeholder')?.setAttribute('href', url);
    }
  }, [company]);
  return (
    <StyledLogin>
      <Grid
        container
        justifyContent="center"
        minHeight="100vh"
        py={20}
        px={4}
        style={{
          background: company?.login_p_bg_color ?? 'white',
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          borderRight={'1px solid #7E7FA1'}
          sx={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}
          p={4}
          container
          justifyContent="center"
          bgcolor="white"
        >
          <Box
            component="img"
            src={company?.logo}
            alt={company?.name}
            sx={{
              width: 200,
              objectFit: 'contain',
            }}
            px={2}
          />
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography fontSize="small" px={10} mb={8} lineHeight={'2rem'}>
              خرید از این فروشگاه، فقط برای همکاران گروه بوتان که دارای کد پرسنلی فعال باشند
              (سایتهای مشمول طرح)، مجاز و میسر می باشد. همکاران مشمول، پس از استخدام به صورت خودکار
              در فروشگاه ثبت نام می‌شوند.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          p={4}
          bgcolor="white"
          container
          justifyContent="center"
          alignItems="center"
          sx={{ borderTopRightRadius: '.5rem', borderBottomRightRadius: '.5rem' }}
        >
          <Grid
            item
            lg={8}
            component="form"
            onSubmit={showOTPBox ? handleLoginSubmit(onLoginSubmit) : handleOTPSubmit(onOTPSubmit)}
            container
            direction="column"
            justifyItems="center"
            gap={2}
            p={4}
            bgcolor="white"
          >
            <Typography variant="h6" fontWeight="bold" textAlign="center" mb={8}>
              ورود به فروشگاه خانوار
            </Typography>
            {!showOTPBox ? (
              <Controller
                name={'mobile'}
                control={otpControl}
                render={({ field }) => (
                  <CTextField
                    {...field}
                    margin="dense"
                    label="ورود با شماره همراه"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setPhone(e.target.value);
                      checkValidation(e.target.value);
                    }}
                    error={phoneErr}
                    helperText={phoneErr && 'لطفا شماره تلفن معتبر وارد نمایید.'}
                    required={true}
                  />
                )}
              />
            ) : (
              <>
                <Controller
                  name={'mobile'}
                  control={loginControl}
                  render={({ field }) => (
                    <CTextField {...field} value={phone} disabled label="شماره موبایل" />
                  )}
                />

                <Controller
                  name={'verification_code'}
                  control={loginControl}
                  render={({ field }) => (
                    <CTextField
                      {...field}
                      margin="dense"
                      label="کد ارسال"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        checkOTP(e.target.value);
                      }}
                      required={true}
                      error={codeErr}
                      helperText={codeErr && 'لطفا کد معتبر وارد نمایید.'}
                    />
                  )}
                />
              </>
            )}

            <CButton
              sx={{ marginTop: 2 }}
              fullWidth
              type="submit"
              variant="outlined"
              color="primary"
              disabled={OTPLoading || loginLoading || codeErr || phoneErr}
              loading={OTPLoading || loginLoading}
            >
              {showOTPBox ? 'ورود' : 'ارسال کد'}
            </CButton>
            {showOTPBox && (
              <Grid container>
                <Grid item xs={12} md={6} lg={6} mt={2}>
                  <CButton
                    fullWidth
                    variant="text"
                    color="secondary"
                    disabled={OTPLoading || loginLoading || counter !== 0}
                    onClick={() => onOTPSubmit({ mobile: phone })}
                    loading={OTPLoading}
                  >
                    ارسال کد
                    {counter !== 0 ? <Grid sx={{ width: 30 }}>{counter}</Grid> : ''}
                  </CButton>
                </Grid>
                <Grid item xs={12} md={6} lg={6} mt={2}>
                  <CButton
                    fullWidth
                    variant="text"
                    color="info"
                    onClick={() => {
                      setShowOTPBox(false);
                      setCounter(INITIALCOUNT);
                    }}
                    rightIcon={<EditOutlined fontSize="small" color="info" />}
                  >
                    &nbsp; ویرایش موبایل
                  </CButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
      {modal?.show && <CModal modal={modal} handleModal={handleModal} />}
    </StyledLogin>
  );
};

export default Login;
