import React from 'react';
import {
  Box,
  ListItem,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemText,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useAppSelector } from '../../redux';
import { RULESDATA } from './mockData';

const Rules = () => {
  let resData = RULESDATA;
  return (
    <Grid p={2}>
      <Box display="flex" justifyContent="center" my={3}>
        <HelpOutlineIcon color="info" sx={{ padding: '0 5px' }} fontSize="large" />
        <Typography fontWeight={'bold'} variant="h5">
          قوانین و مقررات
        </Typography>
      </Box>
      <Box my={5} pt={2}>
        {resData?.length > 0 &&
          resData?.map((rule) => {
            return (
              <Accordion key={rule.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight={'bold'} mx={2}>
                    {rule.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box px={2}>
                    {rule.description ? <Typography>{rule.description}</Typography> : null}

                    <Box mx={2}>
                      {rule.list ? (
                        <List
                          sx={{ listStyle: rule?.list?.style ?? 'decimal', pl: 4, my: 0, py: 0 }}
                        >
                          {rule?.list?.items?.map((item, index) => {
                            return (
                              <ListItem
                                sx={{ display: 'list-item', lineHeight: '2.1rem' }}
                                key={index}
                              >
                                <ListItemText
                                  primary={item.description}
                                  sx={{ lineHeight: '2.1rem' }}
                                />
                                {item?.list ? (
                                  <List
                                    sx={{
                                      listStyle: item?.list.style ?? 'decimal',
                                      pl: 4,
                                    }}
                                  >
                                    {item?.list.items?.map((listItem, index) => {
                                      return (
                                        <ListItem sx={{ display: 'list-item' }} key={index}>
                                          <ListItemText primary={listItem.description} />
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                ) : null}
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : null}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Box>
    </Grid>
  );
};
export default Rules;
