import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import MenuIcon from '@mui/icons-material/Menu';
import {
  StyledAppMenu,
  StyledButanLogoBox,
  StyledPurchaseDropDownItem,
  StyledSubMenu,
} from './AppMenu.styles';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem, removeLocalStorageItem } from '../../utils/local-storage';
import { AccountBalanceWallet, Logout } from '@mui/icons-material';
import {
  selectTotalQuantityShopCart,
  setCategories,
  setProfile,
  setShop,
  setPurchaseBalance,
  useAppDispatch,
  useAppSelector,
  useGetCategoriesQuery,
  useGetProfileQuery,
  useGetPurchaseBalanceQuery,
  useGetShopCartQuery,
} from '../../redux';
import { useSelector } from 'react-redux';
import { toFarsiNumber } from '../../utils/helper';
import { protectedRoutes } from '../../routes/protected';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import MenuIconButton from './components/MenuIconButton';
import HomeIcon from '@mui/icons-material/Home';
import { SearchBox } from './components/SearchBox';
import CategoryMenuItems from './components/CategoryMenuItems';
import { thousandSeparator } from '../../utils/price';

const MOBILEMENUID = 'primary-account-menu-mobile';
const menuId = 'primary-account-menu';
export default function AppHeader() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenu, setShowSub] = useState<null | string>(null);
  const [scrollY, setScrollY] = useState<number>(0);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();
  const { data: categories, isLoading } = useGetCategoriesQuery({});
  const { data: shopCart } = useGetShopCartQuery({});
  const { data: profileData } = useGetProfileQuery({});
  const { data: purchaseLimitData } = useGetPurchaseBalanceQuery({});
  const dispatch = useAppDispatch();
  const shopCount = useSelector(selectTotalQuantityShopCart);

  const SCROLLTRIGGERHEIGHT: number = 200;
  const company = useAppSelector((state) => state.companyInfo.data);
  const auth = getLocalStorageItem(`${window.location.host}-token`);

  useEffect(() => {
    if (typeof shopCart !== 'undefined') {
      dispatch(setShop(shopCart.data));
    }
    if (typeof profileData !== 'undefined') {
      dispatch(setProfile(profileData.data));
    }
    if (typeof categories?.data !== 'undefined' && categories?.data?.length > 0) {
      dispatch(setCategories(categories));
    }
    if (typeof purchaseLimitData !== 'undefined') {
      dispatch(setPurchaseBalance(purchaseLimitData.data));
    }
  }, [shopCart, profileData, categories, purchaseLimitData]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSub(event.currentTarget.ariaLabel);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    handleMobileMenuClose();
  }, []);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setShowSub(event.currentTarget.ariaLabel);
  };
  const handleLogout = useCallback(() => {
    removeLocalStorageItem(`${window.location.host}-token`);
    navigate('/login');
  }, [navigate]);

  const listenToScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    // Adding event listener when the component mounts
    window.addEventListener('scroll', listenToScroll);
    return () => {
      // Removing event listener when the component unmounts
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  const renderMenu = useMemo(() => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {protectedRoutes?.map((route) => {
          return route.parent === 'profile' ? (
            <MenuItem
              key={route.id}
              onClick={() => {
                handleMenuClose();
                navigate(route.path, { replace: true });
              }}
            >
              <IconButton size="small">
                <ChecklistRtlIcon color="secondary" />
              </IconButton>
              <Typography fontSize="small">{route.name}</Typography>
            </MenuItem>
          ) : null;
        })}
        {purchaseLimitData?.data?.has_purchase_limit && (
          <MenuItem>
            <StyledPurchaseDropDownItem>
              <Box display={'flex'} alignItems={'center'} mr={4}>
                <IconButton size="small">
                  <AccountBalanceWallet color="secondary" />
                </IconButton>
                <Typography fontSize="small">سقف خرید</Typography>
              </Box>
              <Box display={'flex'}>
                <Typography fontSize="small">
                  <Box component={'span'} dir={'ltr'}>
                    {thousandSeparator(purchaseLimitData?.data?.purchase_limit ?? 0)}
                  </Box>
                  &nbsp;ریال
                </Typography>
              </Box>
            </StyledPurchaseDropDownItem>
          </MenuItem>
        )}

        <MenuItem onClick={() => handleLogout()}>
          <IconButton size="small" aria-label="logout">
            <Logout color="secondary" />
          </IconButton>
          <Typography fontSize="small">خروج</Typography>
        </MenuItem>
      </Menu>
    );
  }, [handleMenuClose, handleLogout, navigate, anchorEl, isMenuOpen]);

  const renderCatMenu = useMemo(() => {
    return (
      <StyledSubMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {categories?.data?.map((cat) => (
          <MenuItem
            key={cat.id}
            aria-label={cat.name}
            onClick={() => {
              navigate(`/categories/${cat.id}`, { replace: true });
              handleMenuClose();
            }}
          >
            <Typography fontSize="small">{cat.name}</Typography>
          </MenuItem>
        ))}
      </StyledSubMenu>
    );
  }, [handleMenuClose, navigate, anchorEl, categories?.data, isLoading, isMenuOpen]);

  const renderMobileMenu = useMemo(() => {
    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={MOBILEMENUID}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem sx={{ display: { xs: 'flex', md: 'none' } }} onClick={handleMenuOpen}>
          <Typography fontSize="small"> دسته بندی‌ها</Typography>
        </MenuItem>
        {protectedRoutes?.map((route) => {
          return (
            <>
              {route.parent === 'subMenu' ? (
                <MenuItem
                  key={route.id}
                  onClick={() => {
                    handleMenuClose();
                    navigate(route.path, { replace: true });
                  }}
                >
                  <Typography fontSize="small">{route.name}</Typography>
                </MenuItem>
              ) : null}
            </>
          );
        })}
      </Menu>
    );
  }, [handleLogout, isMobileMenuOpen, mobileMoreAnchorEl]);

  return (
    <StyledAppMenu sx={scrollY >= SCROLLTRIGGERHEIGHT ? { position: 'fixed', top: 0 } : null}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid>
          <AppBar position="static" color={undefined}>
            <Container>
              <Toolbar sx={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <IconButton
                    sx={{ p: 1 }}
                    size="large"
                    aria-label="home"
                    aria-controls={menuId}
                    onClick={() => navigate('/')}
                    color="inherit"
                  >
                    <HomeIcon />
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <IconButton
                    sx={{ p: 1 }}
                    size="large"
                    aria-label="show more"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <SearchBox />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <CategoryMenuItems items={categories?.data} activeItem={categories?.data[0]} />
                </Box>

                <Box sx={{ flexGrow: 1 }} />
                <MenuIconButton
                  element={<PersonIcon />}
                  label="profile"
                  menuId={menuId}
                  onClick={handleMenuOpen}
                />
                <MenuIconButton
                  element={
                    shopCount > 0 ? (
                      <Badge badgeContent={toFarsiNumber(String(shopCount))} color="primary">
                        <ShoppingBasketIcon />
                      </Badge>
                    ) : (
                      <ShoppingBasketIcon />
                    )
                  }
                  label={`there are ${shopCount} products in basket`}
                  menuId={menuId}
                  onClick={() => navigate('/shop-cart')}
                />

                <StyledButanLogoBox>
                  <Box
                    component="img"
                    width={100}
                    src={company?.logo}
                    alt={company?.name}
                    onClick={() => (auth ? navigate('/') : navigate('/login'))}
                    px={2}
                  />
                </StyledButanLogoBox>
              </Toolbar>
            </Container>
          </AppBar>
        </Grid>
        {renderMobileMenu}
        {subMenu === 'profile' ? renderMenu : renderCatMenu}
      </Box>
    </StyledAppMenu>
  );
}
